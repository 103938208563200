import "swiper/css"
import "swiper/css/bundle"

import { FC, useContext } from "react"
import { Blok } from "storyblok-react"
import {
  A11y,
  Autoplay,
  EffectCards,
  EffectCoverflow,
  EffectCreative,
  EffectCube,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
} from "swiper"
import { Swiper as SwiperReact, SwiperSlide as SwiperReactSlide } from "swiper/react"
import { SwiperModule } from "swiper/types"
import { SwiperStoryblok } from "../../@types/storyblok"
import { Resolver } from "../resolver/resolver"
import { HeaderContext } from "../../providers/header"

export const Swiper: FC<Partial<Blok<SwiperStoryblok>>> = ({ blok }: Partial<Blok<SwiperStoryblok>>) => {
  const { slides, effect = "slide", pagination = false, lazy = true, autoplay = false, delay = 5000 }: SwiperStoryblok = blok
  const effectMap: Record<string, SwiperModule> = {
    cards: EffectCards,
    coverflow: EffectCoverflow,
    creative: EffectCreative,
    cube: EffectCube,
    fade: EffectFade,
    flip: EffectFlip,
  }
  const { setHamburgerMenuColours } = useContext(HeaderContext)
  const modules = [A11y, Autoplay, Navigation, effect ? effectMap[effect] : null].filter((mod) => !!mod)
  const autoplayProps = autoplay ? {
    delay,
    disableOnInteraction: false,
  } : false

  if (pagination) {
    modules.push(Pagination)
  }

  const handleProgress = ({ realIndex }) => {
    const colours = [
      slides?.[realIndex]?.mobileHamburgerMenuColour,
      slides?.[realIndex]?.desktopHamburgerMenuColour,
    ]

    setHamburgerMenuColours(colours)
  }

  return (
    <SwiperReact
      onProgress={handleProgress}
      modules={modules}
      lazy={lazy}
      observer={true}
      observeParents={true}
      pagination={{ clickable: true }}
      navigation={false}
      effect={effect}
      autoplay={autoplayProps}
    >
      {slides?.map((slide, index: number) => (
        <SwiperReactSlide key={index}>
          <Resolver blok={slide} />
        </SwiperReactSlide>
      ))}
    </SwiperReact>
  )
}
