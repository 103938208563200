import { FC } from "react"
import tw, { styled } from "twin.macro"

export type VideoProps = {
  src?: string
  aspectRatio?: number
}

const VideoContainer = tw.div`
  relative overflow-hidden
`
const VideoPadding = styled.div<Pick<VideoProps, "aspectRatio">>`
  ${({ aspectRatio }) => aspectRatio ? `padding-bottom: ${100 * aspectRatio}%` : null}
`
const VideoPlayer = tw.video`
  absolute top-1/2 left-1/2 w-auto h-full transform-gpu skew-x-0 skew-y-0 scale-100 rotate-0 translate-x-[-50%] translate-y-[-50%]
`

export const Video: FC<VideoProps> = ({ src, aspectRatio = 0.5625 }: VideoProps) => {
  if (!src) {
    return null
  }

  return (
    <VideoContainer>
      <VideoPadding aspectRatio={aspectRatio} />
      <VideoPlayer src={src} muted={true} autoPlay={true} loop={true} playsInline={true} />
    </VideoContainer>
  )
}
