import loadable from '@loadable/component'
import { FC } from 'react'
import { TextImageStoryblok } from '../../@types/storyblok'
import { Blok } from 'storyblok-react'
import tw, { styled } from 'twin.macro'
import { styles } from '../../styles/style-map'
import { Resolver } from '../resolver/resolver'
import { LockUp } from '../logo/logo'
const Editable = loadable(() => import(/* webpackChunkName: "storyblok-react" */ 'storyblok-react'))

const Container = tw.section`
  w-full flex flex-col md:flex-row
`
const Left = styled.div<{ width: keyof typeof styles.width }>`
  ${tw`flex items-center`}
  ${({ width }) => (width ? styles.width[width] : styles.width['.5'])}
`
const Right = styled.div<{ width: keyof typeof styles.width }>`
  ${({ width }) => (width ? styles.width[width] : styles.width['.5'])}
`
const LockUpContainer = tw.span`
  hidden pt-4 md:block
`

export const TextImage: FC<Partial<Blok<TextImageStoryblok>>> = ({ blok }: Partial<Blok<TextImageStoryblok>>) => {
  const { _uid, split, left, right } = blok
  const [leftWidth, rightWidth] = split.split(',')

  return (
    <Editable content={blok} key={_uid}>
      <Container>
        <Left width={leftWidth}>
          <Resolver blok={left?.[0]}>
            <LockUpContainer>
              <LockUp width={40} height={120} />
            </LockUpContainer>
          </Resolver>
        </Left>
        <Right width={rightWidth}>
          <Resolver blok={right?.[0]} />
        </Right>
      </Container>
    </Editable>
  )
}
