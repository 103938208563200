import { sbEditable } from "@storyblok/storyblok-editable"
import { FC, useContext } from "react"
import { Blok } from "storyblok-react"
import { useStoryblok } from "../../hooks/useStoryblok"
import { HeaderContext } from "../../providers/header"
import { Resolver } from "../resolver/resolver"

export const Page: FC<Blok<any>> = ({ story: originalStory, location }: Blok<any>) => {
  const story = useStoryblok(originalStory, location)
  const { setHasHero } = useContext(HeaderContext)
  const content = typeof story.content === "string" ? JSON.parse(story.content) : story.content
  const resolvedComponents = content.body && content.body.map((child: Blok<any>, index: number) => {
    if (index === 0) {
      if ((child.component !== "swiper" && child.component !== "hero")) {
        setHasHero(false)
      } else {
        setHasHero(true)
      }
    }
    return <Resolver blok={child} key={child._uid}/>
  })

  return (
    <div {...sbEditable(content)}>
      {resolvedComponents}
    </div>
  )
}
