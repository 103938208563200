import loadable from "@loadable/component"
import { FC }  from "react"
import { Container } from "../container/container"
import { Divider } from "../divider/divider"
import { Image } from "../image/image"
import { Hero } from "../hero/hero"
import { Heading } from "../heading/heading"
import { Placeholder } from "../placeholder/placeholder"
import { Text } from "../text/text"
import { ContactForm } from "../contact-form/contact-form"
import { Blok } from "storyblok-react"
import { Swiper } from "../swiper/swiper"
import { PageButton } from "../page-button/page-button"
import { TextImage } from "../text-image/text-image"
import { TextLink } from "../text-link/text-link"
import { Testimonial } from "../testimonial/testimonial"

const SbEditable = loadable(() => import(/* webpackChunkName: "storyblok-react" */"storyblok-react"))
interface ComponentMap {
  [key: string]: FC<Blok<any>>
}

const ContainerWrapper = ({ blok, children }: Blok<any>) => {
  const { children: childComponents, _uid, ...rest } = blok
  return (
    <SbEditable content={blok} key={_uid}>
      <Container {...rest}>
        {childComponents && childComponents.map((child: Blok<any>) => <Resolver blok={child} key={child._uid} />)}
        {children}
      </Container>
    </SbEditable>
  )
}

export const Components: ComponentMap = {
  "contact-form": ContactForm,
  "container": ContainerWrapper,
  "divider": Divider,
  "heading": Heading,
  "hero": Hero,
  "image": Image,
  "text": Text,
  "swiper": Swiper,
  "page-button": PageButton,
  "text-image": TextImage,
  "text-link": TextLink,
  "testimonial": Testimonial,
}

export const Resolver: FC<Blok<any>> = ({ blok, children }) => {
  if (Components[blok.component]) {
    const Component = Components[blok.component]
    return <Component blok={blok}>{children}</Component>
  }

  return <Placeholder name={blok.component} />
}
