import { styled, TwStyle } from 'twin.macro'
import { styles } from '../../styles/style-map'

export type BaseProps = {
  [key in keyof typeof styles]: Record<string, Record<string, TwStyle>>
}

const propsToCss = (props: BaseProps) => {
  let classes: TwStyle[] = []

  Object.keys(props).forEach((key: string) => {
    const splitKey = key.split('_')
    const node = styles[key] || styles[key]?.[props[key]] || styles[splitKey?.[0]]?.[splitKey?.[1]];

    if (!node) {
      return
    }

    classes.push(node[props[key]])
  })
  return classes
}

export const Base = styled.div<BaseProps>`
  ${(props) => propsToCss(props)}
`
