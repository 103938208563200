import loadable from '@loadable/component'
import { FC } from 'react'
import tw, { styled } from 'twin.macro'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import { Image as StoryblokImage } from '@storyofams/storyblok-toolkit'
import { HeroStoryblok } from '../../@types/storyblok'
import { Desktop, Mobile } from '../../styles/utility'
import { Resolver } from '../resolver/resolver'
import { Blok } from 'storyblok-react'
import { Video } from '../video/video'
import { styles } from '../../styles/style-map'
import { getFocalPoint, getOriginalDimensions } from '../../utils/images'

const SbEditable = loadable(() => import(/* webpackChunkName: "storyblok-react" */ 'storyblok-react'))

const HeroContainer = styled.section<{ bg: keyof typeof styles.colour.bg }>`
  ${tw`relative`}
  ${({ bg }) => (bg ? styles.colour.bg[bg] : styles.colour.bg.light)}
`

const HeroContent = tw.div`
  absolute bottom-12 mx-auto items-center text-center w-full px-2
`

export const Hero: FC<Partial<Blok<HeroStoryblok>>> = ({ blok }: Partial<Blok<HeroStoryblok>>) => {
  const {
    _uid,
    desktopAspectRatio,
    desktopBackgroundColour,
    desktopContent,
    desktopHidden,
    desktopImageOrVideo,
    lazy,
    mobileAspectRatio,
    mobileBackgroundColour,
    mobileContent,
    mobileHidden,
    mobileImageOrVideo,
  } = blok

  const videoExtensions = ['mp4', 'mov']
  const onlyHasOneImage = !(!!desktopImageOrVideo && !!mobileImageOrVideo)
  const mobileDimensions = getOriginalDimensions(mobileImageOrVideo?.filename)
  const mobileFluidProps = getFluidGatsbyImage(mobileImageOrVideo?.filename)
  const mobileFocalPoint = getFocalPoint(mobileImageOrVideo.focus, mobileDimensions)
  const desktopDimensions = getOriginalDimensions(desktopImageOrVideo?.filename)
  const desktopFluidProps = getFluidGatsbyImage(desktopImageOrVideo?.filename || mobileImageOrVideo?.filename)
  const desktopFocalPoint = getFocalPoint(
    desktopImageOrVideo.focus || mobileImageOrVideo.focus,
    desktopDimensions || mobileDimensions
  )
  const isMobileVideo = videoExtensions.includes(mobileImageOrVideo?.filename?.split('.')?.pop())
  const isDesktopVideo = videoExtensions.includes(
    desktopImageOrVideo?.filename?.split('.')?.pop() || mobileImageOrVideo?.filename?.split('.')?.pop()
  )

  const mobileImageProps = {
    ...mobileFluidProps,
    ...mobileImageOrVideo,
    ...mobileFocalPoint,
    aspectRatio: mobileAspectRatio,
    lazy,
  }

  const desktopImageProps = {
    ...desktopFluidProps,
    ...desktopImageOrVideo,
    ...desktopFocalPoint,
    aspectRatio: desktopAspectRatio,
    lazy,
  }

  return (
    <SbEditable content={blok} key={_uid}>
      {onlyHasOneImage && <StoryblokImage {...mobileImageProps} />}
      {!onlyHasOneImage && !mobileHidden && mobileImageOrVideo && (
        <Mobile>
          <HeroContainer bg={mobileBackgroundColour}>
            {isMobileVideo && <Video src={mobileImageOrVideo?.filename} aspectRatio={mobileAspectRatio} />}
            {!isMobileVideo && <StoryblokImage {...mobileImageProps} />}
            <HeroContent>
              {mobileContent.map((blok: Blok<any>) => (
                <Resolver blok={blok} />
              ))}
            </HeroContent>
          </HeroContainer>
        </Mobile>
      )}
      {!onlyHasOneImage && !desktopHidden && desktopImageOrVideo && (
        <Desktop>
          <HeroContainer bg={desktopBackgroundColour || mobileBackgroundColour}>
            {isDesktopVideo && (
              <Video
                src={desktopImageOrVideo?.filename || mobileImageOrVideo?.filename}
                aspectRatio={desktopAspectRatio}
              />
            )}
            {!isDesktopVideo && <StoryblokImage {...desktopImageProps} />}
            <HeroContent>
              {(mobileContent || desktopContent).map((blok: Blok<any>) => (
                <Resolver key={blok._uid} blok={blok} />
              ))}
            </HeroContent>
          </HeroContainer>
        </Desktop>
      )}
    </SbEditable>
  )
}
