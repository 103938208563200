import { FC } from 'react'
import { Blok } from 'storyblok-react'
import { styled } from "twin.macro"
import { ContainerStoryblok } from '../../@types/storyblok'
import { Base } from '../base/base'

export type ContainerProps = {}

const ContainerWrapper = styled(Base)<ContainerProps>``

export const Container: FC<Partial<Blok<ContainerStoryblok>>> = (props: Partial<Partial<Blok<ContainerStoryblok>>>) => {
  return (
    <ContainerWrapper {...props} />
  )
}
