import { FC } from 'react'
import { Blok } from 'storyblok-react'
import tw, { styled } from 'twin.macro'
import { TextLinkStoryblok } from '../../@types/storyblok'
import { styles } from '../../styles/style-map'
import { Link } from '../link/link'

const LinkArrow = styled.span<{ colour: keyof typeof styles.colour }>`
  ${tw`ml-0-7`}
  ${({ colour }) => colour ? styles.colour[colour] : styles.colour.copy}
`

export const TextLink: FC<Partial<Blok<TextLinkStoryblok>>> = ({ blok }: Partial<Blok<TextLinkStoryblok>>) => {
  const { label, page, arrow, bold, button, hoverColour, colour, focusColour, underline } = blok

  return (
    <Link
      bold={bold}
      colour={colour}
      hoverColour={hoverColour}
      focusColour={focusColour}
      underline={underline}
      button={button}
      to={`/${page?.cached_url}`}
    >
      {label}
      {arrow && <LinkArrow colour={hoverColour} aria-hidden={true}>&gt;</LinkArrow>}
    </Link>
  )
}
