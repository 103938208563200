import { Blok } from 'storyblok-react'
import { PageStoryblok } from '../@types/storyblok'
import { Layout } from '../components/layout/layout'
import { Page } from '../components/page/page'
import { SEO } from '../components/seo/seo'
import { useStoryblok } from '../hooks/useStoryblok'
import { HeaderProvider } from '../providers/header'

const PageTemplate = ({ pageContext, location }: { pageContext: any; location: Location }) => {
  const { story, isHome } = pageContext
  const {
    content: { seoTitle, seoDescription, openGraphImage, openGraphDescription, openGraphTitle },
  }: Blok<PageStoryblok> = story
  const storyLive = useStoryblok(story, location)

  return (
    <HeaderProvider>
      <Layout isHome={isHome}>
        <SEO
          title={seoTitle}
          description={seoDescription}
          openGraphTitle={openGraphTitle}
          openGraphDescription={openGraphDescription}
          openGraphImage={openGraphImage?.filename}
        />
        <Page story={storyLive} location={location} />
      </Layout>
    </HeaderProvider>
  )
}

export default PageTemplate
