export const getFocalPoint = (focus: string, dimensions: { width?: number; height?: number } | null) => {
  if (!focus || (!dimensions || !dimensions.width || !dimensions.height)) {
    return null
  }
  const [pos] = focus.split(':')
  const [x, y] = pos.split('x')
  const focusX = Math.floor(Math.min(Math.max((parseInt(x, 10) / dimensions?.width) * 100, 0), 100))
  const focusY = Math.floor(Math.min(Math.max((parseInt(y, 10) / dimensions?.height) * 100, 0), 100))

  return {
    focusX,
    focusY,
  }
}

export const getOriginalDimensions = (url: string) => {
  if (!url) {
    return null
  }

  const dimensions = url.match(/\/(\d*)x(\d*)\//)

  if (!dimensions) {
    return null
  }

  const width = parseInt(dimensions?.[1]) || undefined
  const height = parseInt(dimensions?.[2]) || undefined

  return {
    width,
    height,
  }
}
