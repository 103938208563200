import { FC } from 'react'
import { Blok } from 'storyblok-react'
import tw, { styled } from 'twin.macro'
import { PageButtonStoryblok } from '../../@types/storyblok'
import { Link } from '../link/link'

const ButtonLink = tw(Link)`
  flex items-center justify-center leading-normal px-0-4 py-4 w-1/2 bg-primary text-light text-secondary text-center uppercase font-extrabold hover:text-light odd:mr-0-2 even:ml-0-2 md:w-full text-20
`
const ButtonLabel = styled.span`
  ${tw`flex flex-1 flex-grow-0 w-auto px-1 md:flex-initial md:px-0`}
`
const ButtonArrow = tw.span`
  text-light ml-0-7
`

export const PageButton: FC<Partial<Blok<PageButtonStoryblok>>> = ({ blok }: Partial<Blok<PageButtonStoryblok>>) => {
  const { label, page } = blok
  return (
    <ButtonLink to={`/${page?.cached_url}`}>
      <ButtonLabel>{label}</ButtonLabel>
      <ButtonArrow aria-hidden={true}>&gt;</ButtonArrow>
    </ButtonLink>
  )
}
