import { FC } from 'react'
import { Blok } from 'storyblok-react'
import tw from 'twin.macro'
import { TestimonialStoryblok } from '../../@types/storyblok'
import { Heading } from '../heading/heading'
import { Text } from '../text/text'

const Container = tw.div`
  text-center pb-8
`
const StartQuoteContainer = tw.span`
  z-10 absolute left-0 top-0
`
const EndQuoteContainer = tw.span`
  z-10 absolute right-0 bottom-0
`
const QuoteContainer = tw.div`
  z-20 relative h-full
`

const StartQuote = () => (
  <svg version="1.1" width="100" height="80" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 46.75">
    <path fill="#fff" d="M19.89,0L0.36,46.75h30.53L39.96,0H19.89z M59.67,0L40.13,46.75h30.53L79.73,0H59.67z" />
  </svg>
)

const EndQuote = () => (
  <svg version="1.1" width="80" height="80" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 46.75">
    <path fill="#fff" d="M60.19,46.75L79.73,0H49.2l-9.07,46.75H60.19z M20.42,46.75L39.96,0H9.43L0.36,46.75H20.42z" />
  </svg>
)

export const Testimonial: FC<Partial<Blok<TestimonialStoryblok>>> = ({ blok }: Partial<Blok<TestimonialStoryblok>>) => {
  const { heading, text } = blok

  return (
    <Container>
      <StartQuoteContainer>
        <StartQuote />
      </StartQuoteContainer>
      <QuoteContainer>
        <Heading blok={{ size: 'h5', textAlignment: 'center', text: heading }} />
        <Text blok={{ text }} />
      </QuoteContainer>
      <EndQuoteContainer>
        <EndQuote />
      </EndQuoteContainer>
    </Container>
  )
}
