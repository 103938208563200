import loadable from '@loadable/component'
import { FC } from 'react'
import { styled } from 'twin.macro'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import { Image as StoryblokImage } from '@storyofams/storyblok-toolkit'
import { ImageStoryblok } from '../../@types/storyblok'
import { Desktop as _Desktop, Mobile as _Mobile } from '../../styles/utility'
import { Blok } from 'storyblok-react'
import { styles } from '../../styles/style-map'
import { getFocalPoint, getOriginalDimensions } from '../../utils/images'

const SbEditable = loadable(() => import(/* webpackChunkName: "storyblok-react" */ 'storyblok-react'))

const Desktop = styled(_Desktop)<{ height: keyof typeof styles.height.md }>`
  ${({ height }) => height ? styles.height.md[height] : null}
`
const Mobile = styled(_Mobile)<{ height: keyof typeof styles.height }>`
  ${({ height }) => height ? styles.height[height] : null}
`
const ImageContainer = styled.div<{ mobileHeight: keyof typeof styles.height, desktopHeight: keyof typeof styles.height.md }>`
  ${({ mobileHeight }) => mobileHeight ? styles.height[mobileHeight] : null}
  ${({ desktopHeight }) => desktopHeight ? styles.height.md[desktopHeight] : null}
`

export const Image: FC<Partial<Blok<ImageStoryblok>>> = ({ blok }: Partial<Blok<ImageStoryblok>>) => {
  const {
    _uid,
    lazy,
    desktopAspectRatio,
    desktopHeight,
    desktopHidden,
    desktopImage,
    mobileAspectRatio,
    mobileHeight,
    mobileHidden,
    mobileImage,
  } = blok

  const onlyHasOneImage = !(!!desktopImage && !!mobileImage)
  const mobileFluidProps = getFluidGatsbyImage(mobileImage?.filename, { aspectRatio: mobileAspectRatio })
  const mobileDimensions = getOriginalDimensions(mobileImage?.filename)
  const desktopFluidProps = getFluidGatsbyImage(mobileImage?.filename || desktopImage?.filename, { aspectRatio: desktopAspectRatio })
  const desktopDimensions = getOriginalDimensions(desktopImage?.filename)
  const mobileFocalPoint = getFocalPoint(mobileImage.focus, mobileDimensions)
  const desktopFocalPoint = getFocalPoint(
    desktopImage.focus || mobileImage.focus,
    desktopDimensions || mobileDimensions
  )

  const mobileImageProps = {
    ...mobileFluidProps,
    ...mobileImage,
    ...mobileFocalPoint,
    aspectRatio: parseFloat(mobileAspectRatio),
    lazy,
    height: mobileHeight === "full" ? "100%" : "auto",
  }

  const desktopImageProps = {
    ...desktopFluidProps,
    ...desktopImage,
    ...desktopFocalPoint,
    aspectRatio: parseFloat(desktopAspectRatio),
    lazy,
    height: desktopHeight === "full" ? "100%" : "auto",
  }

  return (
    <SbEditable content={blok} key={_uid}>
      <ImageContainer mobileHeight={mobileHeight} desktopHeight={desktopHeight}>
        {onlyHasOneImage && (
          <StoryblokImage {...mobileImageProps} />
        )}
        {!onlyHasOneImage && !mobileHidden && mobileImage && (
          <Mobile height={mobileHeight}>
            <StoryblokImage {...mobileImageProps} />
          </Mobile>
        )}
        {!onlyHasOneImage && !desktopHidden && desktopImage && (
          <Desktop height={desktopHeight}>
            <StoryblokImage {...desktopImageProps} />
          </Desktop>
        )}
      </ImageContainer>
    </SbEditable>
  )
}
