import loadable from '@loadable/component'
import { FC } from 'react'
import { Blok } from 'storyblok-react'
import tw, { styled } from 'twin.macro'
import { HeadingStoryblok } from '../../@types/storyblok'
import { styles } from '../../styles/style-map'

const SbEditable = loadable(() => import(/* webpackChunkName: "storyblok-react" */"storyblok-react"))

export const HeadingContainer = styled.div<Pick<Blok<HeadingStoryblok>,  "textAlignment" | "underline">>`
  ${({ textAlignment }) => textAlignment ? styles.textAlign[textAlignment] : styles.textAlign.left}
`
export const Tag = styled.h1<Pick<Blok<HeadingStoryblok>, "size" | "colour" | "upperCase" | "textAlignment" | "underline">>`
  ${tw`pb-1 font-extrabold font-heading`}
  ${({ colour }) => !!colour ? styles.colour[colour] : styles.colour.primary}
  ${({ upperCase }) => upperCase ? tw`uppercase` : null}
  ${({ underline }) => underline ? tw`inline-block border-b-8 border-solid border-secondary` : null }
  ${({ textAlignment }) => textAlignment ? styles.textAlign[textAlignment] : styles.textAlign.left}
  ${({ size }) => {
    switch (size) {
      case "h5":
        return tw`vr-normal-20`
      case "h4":
        return tw`vr-normal-26`
      case "h3":
        return tw`vr-normal-32`
      case "h2":
        return tw`vr-normal-38`
      case "h1":
      default:
        return tw`vr-normal-44`
    }
  }}
`

export const Heading: FC<Partial<Blok<HeadingStoryblok>>> = ({ blok }: Partial<Blok<HeadingStoryblok>>) => {
  const { _uid, text, size, upperCase = false, colour = "primary", textAlignment = "left", underline = false } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      <HeadingContainer underline={underline} textAlignment={textAlignment}>
        <Tag as={size} size={size} colour={colour} upperCase={upperCase} textAlignment={textAlignment} underline={underline}>{text}</Tag>
      </HeadingContainer>
    </SbEditable>
  )
}
