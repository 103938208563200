import loadable from "@loadable/component"
import { FC } from "react"
import { Blok } from "storyblok-react"
import tw, { styled } from "twin.macro"
import { ContactFormStoryblok } from "../../@types/storyblok"
import { Button } from "../button/button"
const SbEditable = loadable(() => import(/* webpackChunkName: "storyblok-react" */"storyblok-react"))

export const FormContainer = tw.form`
  vr-normal-16 flex flex-row flex-wrap
`
export const FormRow = tw.div`
  flex flex-col py-1 items-start w-full md:w-1/2 md:even:pl-2 md:odd:pr-2
`
const FormRowFull = styled(FormRow)`
  ${tw`items-center w-full md:w-full`}
`
export const FormLabel = tw.label`
  vr-double-16
`
export const FormTextInput = tw.input`
  vr-normal-16 w-full px-2 py-1 mt-1 border-0 border-b-2 bg-accent
`
export const FormTextArea = tw.textarea`
  vr-normal-16 p-1
`
export const FormHoneypot = tw.div`
  hidden
`
export const FormButton = styled(Button)`
  ${tw`mt-6 border-0`}
`

export const ContactForm: FC<Partial<Blok<ContactFormStoryblok>>> = ({ blok }: Partial<Blok<ContactFormStoryblok>>) => {
  const { _uid, name, redirect_uri } = blok

  return (
    <SbEditable content={blok} key={_uid}>
      <FormContainer name={name} method="POST" action={`/${redirect_uri?.cached_url}`} data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value={name} />
        <FormHoneypot aria-hidden>
          <FormLabel htmlFor="form-bot-field">Trick or treat *</FormLabel>
          <FormTextInput id="form-bot-field" name="bot-field" />
        </FormHoneypot>
        <FormRow>
          <FormLabel htmlFor="form-your-first-name">First Name *</FormLabel>
          <FormTextInput id="form-your-first-name" name="first-name" required />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-your-last-name">Last Name *</FormLabel>
          <FormTextInput id="form-your-last-name" name="last-name" required />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-your-company">Your Company *</FormLabel>
          <FormTextInput id="form-your-company" name="company" required />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-your-position">Your Position *</FormLabel>
          <FormTextInput id="form-your-position" name="position" required />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-your-email">Your Email *</FormLabel>
          <FormTextInput id="form-your-email" type="email" name="email" required />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-your-phone">Your Phone *</FormLabel>
          <FormTextInput id="form-your-phone" type="phone" name="phone" required />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-preferred-contact-method">Preferred Contact Method</FormLabel>
          <FormTextInput id="form-preferred-contact-method" name="preferred-contact-method" />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="form-preferred-contact-time">Preferred Contact Time</FormLabel>
          <FormTextInput id="form-preferred-contact-time" name="preferred-contact-time" />
        </FormRow>
        <FormRowFull>
          <FormButton type="submit">
            Submit
          </FormButton>
        </FormRowFull>
      </FormContainer>
    </SbEditable>
  )
}
