import loadable from '@loadable/component'
import { FC } from 'react'
import tw, { styled } from 'twin.macro'
import { render } from 'storyblok-rich-text-react-renderer'
import { TextStoryblok } from '../../@types/storyblok'
import { Blok } from 'storyblok-react'
const Editable = loadable(() => import(/* webpackChunkName: "storyblok-react" */ 'storyblok-react'))

const Container = styled.div`
  ${tw`vr-normal-16`}
  p:not(:last-child) {
    ${tw`mb-2-4`}
  }
`

export const Text: FC<Partial<Blok<TextStoryblok>>> = ({ blok }: Partial<Blok<TextStoryblok>>) => {
  const { _uid, text } = blok
  return (
    <Editable content={blok} key={_uid}>
      <Container>{typeof text === "string" ? text : render(text)}</Container>
    </Editable>
  )
}
